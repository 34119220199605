<template>
  <NodeViewWrapper
    class="attachment-zone"
    v-bind="nodeViewAttributes"
  >
    <LegalReportsBdeseAttachmentTable
      :attachments="attachments"
      :is-in-attachment-manager="false"
    />
  </NodeViewWrapper>
</template>

<script lang="ts">
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { ATTACHMENT_ZONE_ATTRIBUTES } from '~/constants/bdese'
import { useBdeseStore } from '~/stores/bdese'
import type { BdeseAttachment } from '~/types/bdese'

export default {
  name: 'EditorAttachmentZone',
  components: {
    NodeViewWrapper
  },
  props: nodeViewProps,
  computed: {
    nodeViewAttributes() {
      return {
        [ATTACHMENT_ZONE_ATTRIBUTES.ATTACHMENT_IDS]:
          this.node.attrs[ATTACHMENT_ZONE_ATTRIBUTES.ATTACHMENT_IDS],
        [ATTACHMENT_ZONE_ATTRIBUTES.UPDATED_AT]:
          this.node.attrs[ATTACHMENT_ZONE_ATTRIBUTES.UPDATED_AT]
      }
    },
    attachments() {
      const { bdese } = useBdeseStore()
      const attachmentIds = JSON.parse(
        this.node.attrs[ATTACHMENT_ZONE_ATTRIBUTES.ATTACHMENT_IDS]
      ) as string[]

      return attachmentIds
        .map(id =>
          bdese!.bdeseAttachments.find(
            attachment => attachment.attachmentId === id
          )
        )
        .filter(Boolean) as BdeseAttachment[]
    }
  }
}
</script>

<style lang="scss">
.attachment-zone {
  margin-bottom: $margin-medium;

  .attachment-table table {
    border: 1px solid $border-ternary;
    box-shadow: none;
  }

  .attachment-table td:not(:last-child),
  .attachment-table th:not(:last-child) {
    border-right: none;
  }
}
</style>
